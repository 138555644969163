import React from 'react';
import '../styles/modalvideo.scss';

const ModalVideo = (props) => {
	return (
		<div
			className={`${props.show ? 'show' : ''} modal`}
			onClick={props.onClose}
		>
			<div className='modal-content' onClick={(e) => e.stopPropagation()}>
				<div className='modal-content-video'>
					<iframe
						id='youtube_player'
						className='modal-content-video-inner'
						width='960'
						height='auto'
						src={`https://www.youtube.com/embed/${props.videoID}?enablejsapi=1`}
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default ModalVideo;
