import React, { useState } from 'react';
import { Link } from 'gatsby';
import Seo from '../components/seo.es';
import LayoutEN from '../components/layout.en';
import SolutionsBlockEN from '../components/solutionsblock.component.en';
import Benefit from '../components/benefit.component';
import Pricing from '../components/pricing.component.en';
import FAQaccordion from '../components/faqaccordion.component.en';
import LatestPostsEN from '../components/latestposts.component.en';
import CTAButton from '../components/ctabutton.component';
import ModalVideo from '../components/modalvideo.component';
import ModalNewsletter from '../components/modalnewsletter.component';

import '../styles/index.scss';
import heroImage from '../static/images/KuFlow-Flujos_hiperautomatizados.gif';

const IndexPage = () => {
	const [show, setShow] = useState(false);
	const [showForm, setShowForm] = useState(false);
	return (
		<LayoutEN>
			<Seo title='Homepage' />
			<section id='hero'>
				<div id='hero-text' className='hero-column'>
					<h1>
						Auto<strong>magic</strong> Workflows
					</h1>
					<p>
						The magic of saving money and time and{' '}
						<strong>making it look like</strong> nothing.
					</p>
					<div className='buttonPanel'>
						<CTAButton color='lblue'>SIGN UP FREE</CTAButton>
						<ModalVideo
							onClose={() => setShow(false)}
							show={show}
							videoID='Duh8pOQlRfA'
						/>
						<a className='btn-primary' onClick={() => setShow(true)}>
							KUFLOW IN 1 MINUTE <strong>▶️</strong>
						</a>
					</div>
				</div>
				<div id='hero-image' className='hero-column'>
					<img src={heroImage} alt='Comfortable working with KuFlow' />
				</div>
			</section>
			<section id='featured-article'>
				<Link to='/blog/en/leaders-automation' className='blink'>
					Which processes are automating BIG companies
				</Link>
			</section>
			<SolutionsBlockEN />
			<section id='benefits'>
				<h2>How KuFlow does it?</h2>
				<Benefit
					posImage='R'
					imageUrl='/images/kuflow_orquesta.png'
					linkTo='/en/solution-workflow/'
					linkText='See more'
				>
					<h3>KuFlow orchestrate</h3>
					<p>
						KuFlow can be defined as a{' '}
						<strong>task orchestrator, both human and automated.</strong>
					</p>
					<p>
						It is in charge of telling each agent which tasks and which
						processes to execute.
					</p>
				</Benefit>
				<Benefit
					posImage='L'
					imageUrl='/images/kuflow_mejora.png'
					linkTo='/en/solution-rpa/'
					linkText='See more'
				>
					<h3>KuFlow improves</h3>
					<p>KuFlow is the closest thing to giving your team superpowers.</p>
					<p>
						Everybody knows{' '}
						<strong>
							which task they have to do, stakeholders are automatically
							informed
						</strong>{' '}
						when there are new developments and{' '}
						<strong>robots take care of errands nobody wants</strong>.
					</p>
				</Benefit>
				<Benefit
					posImage='R'
					imageUrl='/images/kuflow_automatiza.png'
					linkTo='/en/solution-orchestration/'
					linkText='See more'
				>
					<h3>KuFlow automates</h3>
					<p>
						Siri can tell you a joke, Alexa can forecast the weather... But{' '}
						<strong>KuFlow works for you</strong>!
					</p>
					<p>
						Do you need to prepare reports, send invoices, order files?{' '}
						<strong>Let your robots take care of it.</strong>
					</p>
					<p>
						The downside? It's not integrated with your washing machine. Yet.
					</p>
				</Benefit>
			</section>
			<Pricing />
			<section id='faq'>
				<h2>Frequently Asked Questions</h2>
				<FAQaccordion />
			</section>
			<LatestPostsEN />
			<section id='subscription-box'>
				<h2>The KuFlow's non-automated monthly 💌</h2>
				<p>Ideas, news and resources packed newsletter to keep you{' '}
				up to date in the world of automation.</p>
				<ModalNewsletter
					onClose={() => setShowForm(false)}
					showForm={showForm}
					lang='en'
				/>
				<a className='btn-primary' onClick={() => setShowForm(true)}>
				Subscribe to the monthly
				</a>
				<p className='button-note'>By subscribing you agree to our
				<a href='/legal' alt='Privacy policy'> privacy policy</a>.</p>
			</section>
		</LayoutEN>
	);
};

export default IndexPage;
