import React from 'react';
import { Link } from 'gatsby';
import '../styles/faqaccordion.scss';

export default function FAQaccordion() {
	return (
		<div className='tabs'>
			<div className='tab'>
				<input type='checkbox' id='chck1' />
				<label className='tab-label' htmlFor='chck1'>
					Is KuFlow suitable for my organization?
				</label>
				<div className='tab-content'>
					<p>
						KuFlow is perfect for any type of organization, but the more complex
						the organization the more you're going to push KuFlow to its limits.
					</p>
					<p>
						Think of KuFlow as a{' '}
						<strong>Tasks Organization and Sh*ts Automation Department</strong>.
					</p>
					<p>
						In a 4 people organization it may be a bit of an exaggeration, but
						when there are 40 brains to coordinate... You'll wish you had this
						department sooner.
					</p>
				</div>
			</div>
			<div className='tab'>
				<input type='checkbox' id='chck2' />
				<label className='tab-label' htmlFor='chck2'>
					In what kind of processes could I use KuFlow?
				</label>
				<div className='tab-content'>
					<p>Virtually anything you can think of:</p>
					<ul>
						<li>
							Purchases approvals, from the initial request to contacting the
							supplier and storing the invoice.
						</li>
						<li>Vacation requests.</li>
						<li>Receive customer feedback and pipeline that information.</li>
						<li>
							Manage any customer relationship from the initial proposal to the
							delivered product.
						</li>
						<li>
							Automatically remind customers of pending documents or
							authorizations.
						</li>
						<li>
							Analyze every X days or hours some web site and prepare a report.
						</li>
					</ul>
					<p>
						If it can be thought of as a flow chart, it can be done in KuFlow.
					</p>
					<p>
						You can read more examples by viewing the use cases using KuFlow for{' '}
						<Link to='/en/solution-workflows'>Workflows</Link>,{' '}
						<Link to='/en/solution-rpa'>Smart Automation</Link> and{' '}
						<Link to='/en/solution-orchestration'>Orchestration</Link>.
					</p>
				</div>
			</div>
			<div className='tab'>
				<input type='checkbox' id='chck3' />
				<label className='tab-label' htmlFor='chck3'>
					What is KuFlow NOT for?
				</label>
				<div className='tab-content'>
					<p>Unfortunately, KuFlow is not a Swiss Army Knife.</p>
					<ul>
						<li>
							It is not a BPM tool. We don't have little boxes and little arrows
							here.
						</li>
						<li>
							It is not going to replace accounting or HR tools, although it can
							get along well with them and do powerful things together.
						</li>
						<li>
							It is not recommended for no-code companies. If just seeing some
							lines of code makes you sick, maybe there are better alternatives.
						</li>
					</ul>
				</div>
			</div>
			<div className='tab'>
				<input type='checkbox' id='chck4' />
				<label className='tab-label' htmlFor='chck4'>
					Do I need a programmer/IT guy?
				</label>
				<div className='tab-content'>
					<p>
						<strong>Hell yeah</strong>.
					</p>
					<p>
						For both flow creation and task automation, you will need someone
						who knows how to talk to machines.
					</p>
					<p>
						Don't have such a profile? Then you'll be interested to know that we
						offer implementation support.
					</p>
				</div>
			</div>
		</div>
	);
}
