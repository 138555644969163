import React from 'react';
import CTAButton from './ctabutton.component';
import '../styles/pricing.scss';

export default function Pricing(props) {
	return (
		<div id='pricing'>
			<h2>Pricing</h2>
			<div className='pricing_columns'>
				<div className='pricing_column'>
					<h4>R2D2 Plan</h4>
					<h5 className='price'>Free</h5>
					<p>
						<span>∞ Unlimited processes</span>
						Your critical processes under control
					</p>
					<p>
						<span>Up to 5 agents</span>
						An agent is a human or a machine
					</p>
					<p>
						<span>200MB of storage</span>
						per organization
					</p>
				</div>
				<div className='pricing_column'>
					<h4>T800 Plan</h4>
					<h5 className='price'>10€/agent</h5>
					<p>
						<span>∞ Unlimited processes</span>
						0,30€ per process execution
					</p>
					<p>
						<span>Up to 10 agents</span>
						More brains and CPUs to work
					</p>
					<p>
						<span>Storage on demand</span>
						0,35€ per GB consumed
					</p>
				</div>
				<div className='pricing_column'>
					<h4>Optimus plan</h4>
					<h5 className='price'>Custom</h5>
					<p>
						<span>∞ Unlimited processes</span>
						Custom price per execution
					</p>
					<p>
						<span>+10 agents</span>
						The sky is the limit
					</p>
					<p>
						<span>Storage on demand</span>
						0,30€ per GB consumed
					</p>
					<CTAButton inverted mail color='lblue'>
						ASK FOR A SPECIFIC PLAN
					</CTAButton>
				</div>
			</div>
		</div>
	);
}
