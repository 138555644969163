import React from 'react';
import { Link } from 'gatsby';
import '../styles/solutionsblock.scss';

export default function SolutionsBlockEN() {
	return (
		<section id='bloque-soluciones'>
			<h2>What KuFlow does?</h2>
			<div id='bloque-soluciones_columnas'>
				<Link className='bloque-soluciones_columna' to='/en/solution-workflow'>
					<h3>Workflows</h3>
					<p>Discover how KuFlow is going to be more helpful than coffee</p>
					<p className='bloque-soluciones_link'>See more</p>
				</Link>
				<Link className='bloque-soluciones_columna' to='/en/solution-rpa'>
					<h3>Smart automation</h3>
					<p>
						Robots taking care of the heaviest tasks. Skynet doesn't know
						anything (yet)
					</p>
					<p className='bloque-soluciones_link'>See more</p>
				</Link>
				<Link
					className='bloque-soluciones_columna'
					to='/en/solution-orchestration'
				>
					<h3>Service orchestration</h3>
					<p>
						Reliable and robust. But be careful, you will forget where the logs
						were
					</p>
					<p className='bloque-soluciones_link'>See more</p>
				</Link>
			</div>
		</section>
	);
}
