import React from 'react';
import '../styles/modalnewsletter.scss';

const ModalNewsletter = (props) => {
	return (
		<div
			className={`${props.showForm ? 'showForm' : ''} modal-newsletter-form`}
			onClick={props.onClose}
		>
			<div className='modal-newsletter-content' onClick={(e) => e.stopPropagation()}>
				<div className='modal-newsletter-header'>
					<p className='modal-newsletter-title'>
						{<a className='button' onClick={props.onClose}>
							X
						</a>}
					</p>
				</div>
				{props.lang == 'es' &&
					<iframe className='subscription-iframe' frameBorder='0' src='https://cdn.forms-content.sg-form.com/9ac5a818-4314-11ed-9fbb-26255c63cc5f'></iframe>
				}                
				{props.lang == 'en' &&
					<iframe className='subscription-iframe' frameBorder='0' src='https://cdn.forms-content.sg-form.com/9ac5a818-4314-11ed-9fbb-26255c63cc5f'></iframe>
				}
				<div className='modal-newsletter-footer'>
					{/*<button className='button' onClick={props.onClose}>
						Cerrar
					</button>*/}
				</div>
			</div>
		</div>
	);
};

export default ModalNewsletter;
